import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import sectionTemplates from './sections';

class HomePage extends Component {
  render() {
    const { data } = this.props;
    const { page, sections, site } = data;
    return (
      <Layout home={true}>
        <Helmet titleTemplate={`%s`}>
          <title>{`${site.siteMetadata.title} | ${
            page.frontmatter.metaTitle
          }`}</title>
          <meta name="description" content={page.frontmatter.metaDescription} />
        </Helmet>
        {page.frontmatter.sections.map(({ name }) => {
          const Section = sectionTemplates[name];
          const section = sections.edges.find(({ node }) => {
            return name === node.frontmatter.name;
          });
          return (
            <Section
              key={section.node.id}
              data={{ ...data, section: section.node }}
            />
          );
        })}
      </Layout>
    );
  }
}

export default HomePage;

export const query = graphql`
  query GetHomePage($id: String!, $sections: String) {
    site {
      siteMetadata {
        title
        copyright
        description
        phone
        email
        linkedInUrl
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        metaTitle
        metaDescription
        sections {
          name
        }
      }
    }
    sections: allMarkdownRemark(
      filter: { frontmatter: { name: { regex: $sections } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            name
            title
            date
            lead
            images {
              image {
                childImageSharp {
                  fluid(maxHeight: 256, maxWidth: 350, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            callToActionText
            callToActionLink
          }
          html
          htmlAst
        }
      }
    }
  }
`;
